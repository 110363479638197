import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PortfolioItemSummary from "../../components/PortfolioItemSummary"

const PortfolioTSC = () => {
  return (
    <Layout bgClass="bg-wattle-light" fgMode="dark">
      <Seo
        title="Temora Shire Council"
        description="Streamlining membership management at the recreation centre"
      />
      <section className="mx-auto text-black bg-wattle-light">
        <div className="flex flex-col items-center mx-4 lg:max-w-screen-lg lg:mx-auto">
          <StaticImage
            height={80}
            alt="Logo - G1 Goldmine"
            src="../../images/logo-temora-shire.svg"
            placeholder="blurred" />
          <h1 className="sr-only">Temora Shire Council</h1>
          <p className="pt-10 text-3xl text-center">Digitising customer facing services.</p>
          <p className="pt-4 text-xl text-center">
            Building upon Stripe's subscription system to deliver a stellar membership experience.
          </p>
        </div>
        <PortfolioItemSummary
          expertise="Design and Implementation"
          platforms="Internet"
          deliverables="API, frontend development"
          href="https://eservices.temora.nsw.gov.au"
          linkText="Temora Shire Council" />
      </section>
      <div className="hidden lg:grid grid-cols-[1fr,800px,1fr] grid-rows-[repeat(7,1fr)]">
        <div className="flex col-start-1 col-end-4 row-start-1 row-end-3 bg-wattle-light">
        </div>
        <div className="col-start-2 col-end-3 row-start-1 row-end-7">
          <div className="shadow-2xl">
            <StaticImage
              alt="G1G Preview"
              src="../../images/screenshot-wattle.png"
              placeholder="dominantColor" />
          </div>
        </div>
      </div>
      <div className="mx-4 mt-10 text-gray-700 lg:mt-0 lg:mx-auto lg:max-w-screen-md">
        <p className="mb-6 text-2xl">
          Temora Shire is a local government area in the Riverina region of New South Wales, Australia. It is on Burley Griffin Way.
          The Shire was created on 1 January 1981 via the amalgamation of Narraburra Shire and the Municipality of Temora.
        </p>
        <p className="mb-6 text-2xl">
          It includes the town of Temora and the small towns of Springdale, Sebastapol, Ariah Park, Gidginbung, Narraburra and Wallundry.
        </p>
      </div>

      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-g1g-darkblue my-14">Automating memberships</h2>
        <p className="mb-6 text-xl">
          Wattle is our master plan to provide a full open sourced platform for the public sector to bring their services online.
          Temora Shire Council has been with us since the beginning. When they approached with their vision of full automating
          the membership process of their recreation centre.
        </p>
        <p className="mb-6 text-xl">
          The design process lead to a solution where the customer can manage their membership via a web based interface. The membership
          system is then able to speak to their access control system and allow and turn the customer's mobile phone into a NFC token
          to gain access to the facility.
        </p>
        <p className="mb-6 text-xl">

        </p>
      </div>

      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-g1g-darkblue my-14">Who we worked with</h2>
        <p className="mb-6 text-xl">
          We built the membership system on top of Stripe's subscription platform taking advantage of monthly and annual billing cycles.
        </p>
        <p className="mb-6 text-xl">
          The software is containerised giving the public sector the freedom to deploy on any cloud provider. We partnered with Linode
          to bring Temora Shire Council online.
        </p>
        <p className="mb-6 text-xl">
          Gallagher Security manufacture and install access control systems, their <a className="underline text-anomaly-red" href="https://security.gallagher.com/en-AU/News-and-Awards/News/Gallagher-launches-cloud-based-security-solution-for-small-and-medium-businesses-in-Australia">cloud infrastructure</a> made it simple to talk back to the infrastructure at the recreation centre.
        </p>

      </div>

    </Layout>
  );
};
export default PortfolioTSC
